import { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons'
import './SearchButton.css'
import TuneIcon from '@mui/icons-material/Tune';
import classNames from 'classnames'

//griplines faBurst

function SearchButton ({ className, ...props }) {
  
  const {onClick, applied, selected} = props
  
  return (
    <div className={classnames('SearchButton', className, )} onClick={onClick}>

        <div className={classnames('button-small', {"selected": selected}, {"applied": applied})}>

          {/* <Icon
            className={applied?'check':""}
            icon={faMagnifyingGlass}
            size="sm"
          /> */}
         
          <TuneIcon/>
  
        </div>
   
    </div>
  )
}

SearchButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}


SearchButton.defaultProps = {
  appled: false,
};


export default memo(SearchButton)
