import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TrainlineTrainIcon.css';


const getIconColor = function (delay) {
    if (delay === null || delay === undefined) {
        return "green"
    }

    if (delay > 0 && delay < 10) {
        return "amber"
    }

    if (delay >= 10) {
        return "red"
    }
    return "green"
}

const getTocStyle = function (tocCode) {

    const a = { "AW": "grey", "CC": "#380040", "CH": "#770088", "CS": "#7f0090", "EM": "#83009a", "ES": "#4300a2", "GC": "#0000ad", "GN": "#0000c5", "GR": "#0009dd", "GW": "#004bdd", "GX": "#007add", "HC": "#008ddd", "HT": "#009bd7", "HX": "#00a4bb", "IL": "#00aaa5", "LE": "#00aa93", "LM": "#00a668", "LO": "#009e28", "LT": "#00a200", "ME": "#00b200", "NT": "#00c400", "NY": "#00d400", "PC": "#00e700", "RT": "#00f700", "SE": "#3bff00", "SJ": "#a1ff00", "SN": "#ccf900", "SR": "#e8f000", "SW": "#f4e200", "TL": "#fdcf00", "TP": "#ffb900", "TW": "#ff9d00", "VT": "#ff5100", "WR": "#ff0900", "XC": "#ee0000", "XR": "#6927A5", "ZB": "#d40000", "ZF": "#cc0000", "ZM": "#cc6c6c", "ZZ": "#cccccc" }
    var color = a.hasOwnProperty(tocCode) ? a[tocCode] : 'grey'
    return { fill: color, fillOpacity: 0.6 }
}

function TrainlineTrainIcon(props) {
    const { delay,
        isFocused,
        size,
        heading,
        tocCode,
        coloring,
        className } = props;

    let iconStyle = {
        height: size,
        width: size,
    };


    iconStyle.transform = 'scale(1.2, 1.2)';
    if (isFocused) {
        iconStyle.transform = 'scale(1.5, 1.5)';
    }
 

    return (
        <div className={classnames('TrainlineTrainIcon', className)}
            style={iconStyle}
        >
            <svg width={size}
                height={size}  viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_8_1730)">
                    <circle  className={`${getIconColor(delay)}`} cx="23" cy="23" r="12" fill="#00A88F" />
                    <circle cx="23" cy="23" r="13.5" stroke="white" strokeWidth="3" />
                </g>
                <path transform={`rotate(${heading - 90},23, 23)`}d="M21.7145 29.2105L26.2059 23.8732C26.5221 23.4975 26.5186 22.9478 26.1978 22.576L21.4624 17.0894" stroke="white" strokeWidth="3" strokeLinecap="round" />
                <defs>
                    <filter id="filter0_d_8_1730" x="0.831917" y="0.831917" width="44.3362" height="44.3362" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="1.43362" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_8_1730" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="2.86723" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_1730" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_1730" result="shape" />
                    </filter>
                </defs>
            </svg>

        </div>
    );
}

TrainlineTrainIcon.propTypes = {
    delay: PropTypes.number,
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
    coloring: PropTypes.string
};

TrainlineTrainIcon.defaultProps = {
    isFocused: false,
    size: 64,
    heading: 0,
    tocCode: undefined,
    coloring: undefined
};

export default React.memo(TrainlineTrainIcon);
